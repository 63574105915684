<template>
    <notificationGroup group="generic">
        <div class="fixed inset-0 flex px-4 py-6 pointer-events-none items-end justify-start z-30">
            <div class="max-w-sm w-full">
                <notification v-slot="{notifications}">
                    <div v-for="notification in notifications" :key="notification.id">
                        <div v-if="notification.type==='info'" class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4">
                            <div class="flex justify-center items-center w-12 bg-blue-500 p-2">
                                <i class="mdi mdi-information-variant text-3xl sm:text-4xl text-yellow-500"></i>
                            </div>
                            <div class="-mx-3 py-2 px-4">
                                <div class="mx-3">
                                    <span class="text-blue-500 font-semibold">{{notification.title}}</span>
                                    <p class="text-gray-600 text-sm">{{notification.text}}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="notification.type==='warning'" class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4">
                            <div class="flex justify-center items-center w-12 bg-yellow-500 p-2">
                                <i class="mdi mdi-alert text-3xl sm:text-4xl text-red-400"></i>
                            </div>
                            <div class="-mx-3 py-2 px-4">
                                <div class="mx-3">
                                    <span class="text-yellow-500 font-semibold">{{notification.title}}</span>
                                    <p class="text-gray-600 text-sm">{{notification.text}}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="notification.type==='error'" class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4">
                            <div class="flex justify-center items-center w-12 bg-red-500 p-2">
                                <i class="mdi mdi-alert text-3xl sm:text-4xl text-yellow-500"></i>
                            </div>
                            <div class="-mx-3 py-2 px-4">
                                <div class="mx-3">
                                    <span class="text-red-500 font-semibold">{{notification.title}}</span>
                                    <p class="text-gray-600 text-sm">{{notification.text}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </notification>
            </div>
        </div>
    </notificationGroup>
</template>

<script>
    export default {
        name: "Toaster"
    }
</script>