require('./sockets.js');

import Vue from 'vue'
import App from './App.vue'
/*import './registerServiceWorker'*/
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

import router from './router'

Vue.config.productionTip = false

import '@/assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css';

import Notifications from "vt-notifications";
Vue.use(Notifications);

import { myUtils } from './myUtils';
Object.defineProperty(Vue.prototype, '$myUtils', { value: myUtils });

let dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')
Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs });

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// Register components in your 'main.js'
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import loadMap from 'highcharts/modules/map';

import proj4 from 'proj4';
window.proj4 = window.proj4 || proj4;

require("highcharts/modules/annotations")(Highcharts)

loadMap(Highcharts)
Vue.use(VueHighcharts, { Highcharts } );
HighchartsMore(Highcharts)
solidGauge(Highcharts)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Highcharts.setOptions({
    lang: {
       shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      }
})

import Tippy from 'v-tippy'
import 'v-tippy/dist/tippy.css'

Vue.use(Tippy, {
    position: 'left',
    touch: true,
    theme: 'translucent'
})

// Vue filters

Vue.filter('numberFormat', function(number, decimals) {
    return numberFormat(number, decimals)
});

Vue.filter('reduceBigNumbers', function(number, decimals) {
    return reduceBigNumbers(number, decimals)
});

Vue.filter('shortDateFormat', function(date) {
    return dayjs(date).format('MMM-YY')
});

Vue.filter('middleDateFormat', function(date) {
    return dayjs(date).format('D-MMM-YY')
});

Vue.filter('longDateFormat', function(date) {
    return dayjs(date).format('ddd D MMM-YY')
});

export function reduceBigNumbers(number, decimals=2) {
    if (number > 1000000 || number < -1000000) {
        return numberFormat(number / 1000000, decimals) + 'M';
    }

    if (number > 1000 || number < -1000) {
        return numberFormat(number / 1000, decimals) + 'K';
    }

    return numberFormat(number, decimals);
}

export function numberFormat(number, decimals) {
    if (!number) {
        number = 0;
    }
    return parseFloat(number).toLocaleString('en', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    }).replace(/,/g, '@').replace('.', ',').replace(/@/g, '.');
}

Vue.mixin({
    data() {
        return {
            backofficeRouteNames: [
                'BackAreas',
                'BackAreaAmounts',
                'BackCourses',
                'BackEditions',
                'BackUsers',
                'BackConnections',
                'BackHpLists',
                'BackNotifications'
            ],
            emptyResultsMsg: 'No hay registros',
            selectOptionMsg: 'Selecciona una opción',
            isNotEditableMsg: 'Click en el botón Editar si quieres hacer algún cambio.',
            millis: 4000,
            boRouteNames: ['BackAreas', 'BackAreaAmounts', 'BackCourses', 'BackEditions', 'BackUsers', 'BackConnections', 'BackHpLists', 'BackNotifications'],
            boAdminRouteNames: ['BackAreas','BackAreaAmounts',  'BackCourses', 'BackEditions', 'BackUsers', 'BackHpLists', 'BackNotifications'],
        }
    },
    computed: {
        isFirefox() {
            let pos = navigator.userAgent.search("irefox");

            return pos >= 0;
        }
    },
    methods: {
        toastErrors(errors) {
            let errorItems = Object.keys(errors)
            for (let i = 0; i < errorItems.length; i++) {
                for (let j = 0; j < errors[errorItems[i]].length; j++) {
                    this.$notify(
                        { group: "generic", title: 'Error', text: errors[errorItems[i]][j], type: 'error'},
                        this.millis
                    );
                }
            }
        },
        showModeNotifier() {
            this.$notify(
                { group: "generic", title: "Entrada bloqueada", text: this.isNotEditableMsg, type: 'info'},
                this.millis
            );
        },
    }
})


import './assets/scss/my-sass.scss'

import VueAnalytics from 'vue-analytics';
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
    id: 'G-XFHWZXN76C',
    router
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
