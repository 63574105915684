import Vue from 'vue';
import { toCookie, toLocal } from './storages';


const store = {
    token: null,
    user: null,

    sidebarIsOpen: false,
    elSelected: false,
    subElSelected: false,

    sectionsIsExpanded: false,
    visibleRowsQtyInTable: 10,

    search: '',
};

toCookie(store, {
    attrs: ['token'],
    days: 365
});

toLocal(store, {attrs: ['token']});

const state = Vue.observable(store);

const actions = {
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
    },
    getUser() {
        return state.user;
    },
    resetAuth() {
        state.token = null;
        state.user = null;
    },
    getElSelected() {
        return state.elSelected;
    },
    setElSelected(elSelected) {
        state.elSelected = elSelected;
    },
    getSubElSelected() {
        return state.subElSelected;
    },
    setSubElSelected(subElSelected) {
        state.subElSelected = subElSelected;
    },
    getSidebarIsOpen() {
        return state.sidebarIsOpen;
    },
    setSidebarIsOpen(sidebarIsOpen) {
        state.sidebarIsOpen = sidebarIsOpen;
    },
    getSectionsIsExpanded() {
        return state.sectionsIsExpanded;
    },
    setSectionsIsExpanded(sectionsIsExpanded) {
        state.sectionsIsExpanded = sectionsIsExpanded;
    },
    getVisibleRowsQtyInTable() {
        return state.visibleRowsQtyInTable;
    },
    getArea(){
        return state.area;
    },
    setArea(area){
        state.area = area;
    },
    getCourse(){
        return state.course;
    },
    setCourse(course){
        state.course = course;
    },
    setSearch(search){
        state.search = search;
    },
};

export {
    state,
    actions
};