<template>
    <Modal :show="show" @close="show = false">
        <div class="flex p-2">
            Se ha encontrado una nueva versión
        </div>
        <div class="flex justify-end">
            <button class="button bg-blue-300 text-gray-800 rounded rounded-full w-30 py-1 px-4  mt-4 focus:outline-none" @click="update">
                Actualizar
            </button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/pages/shared/modals/Modal'
export default {
    name: "UpdateDialog",
    components: {
        Modal
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        async update() {
            this.show = false;
            await this.$workbox.messageSW({type: "SKIP_WAITING"});
        }
    },
    created() {
        if (this.$workbox) {
            this.$workbox.addEventListener("waiting", () => {
                this.show = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.update-dialog {
    position: fixed;
    left: 50%;
    bottom: 64px;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 576px;
    min-width: 19rem;
}
</style>