<template>
    <div
        v-if="show"
        class="p-3 fixed inset-0 flex bg-gray-900 bg-opacity-75 z-50"
        @click.self="close"
    >
        <div class="m-auto max-w-sm w-full px-6 py-5 bg-notification rounded-md shadow-modal text-gray-500">
            <slot
                :close="close"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>