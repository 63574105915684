<template>
    <Modal :show="show" @close="show = false" v-slot="{ close }">
        <p v-if="notification" class="font-semibold">{{ notification.title }}</p>
        <p v-if="notification" class="mt-4">{{ notification.body }}</p>
        <div class="mt-6 flex justify-end">
            <button type="button" class="button bg-blue-300 text-gray-800 rounded rounded-full py-1 px-4 mt-4 focus:outline-none" @click="close">
                Cerrar
            </button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/pages/shared/modals/Modal'
import { Plugins } from '@capacitor/core';
const { PushNotifications, Device } = Plugins;

export default {
    name: "NotificationModal",
    components: {
        Modal
    },
    data() {
        return {
            show: false,
            notification: null,
        };
    },
    methods: {
        fcmPushNotificationReceivedListener() {
            PushNotifications.addListener('pushNotificationReceived',(notification) => {
                console.log(JSON.stringify(notification));
                this.notification = notification.data;
                this.show = true;
            });
        },
        fcmPushNotificationActionPerformedListener() {
            PushNotifications.addListener('pushNotificationActionPerformed',(notification) => {
                console.log(JSON.stringify(notification));
                this.notification = notification.notification.data;
                this.show = true;
            });
        },
        async fcm() {
            let deviceInfo = await Device.getInfo();
            if (deviceInfo.platform === 'web') {
                return;
            }
            await this.fcmPushNotificationReceivedListener();
            await this.fcmPushNotificationActionPerformedListener();
        }
    },
    mounted() {
        this.fcm();
    }
}
</script>

<style scoped>

</style>